@use "breakpoints";
@use "colors";

$font-weight-thin: 100;
$font-weight-extralight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Breakpoint-dependent typography - Titles
// span is used inside titles for the "highlighted" section

@mixin text-marquee-title-uppercase {
  @include text-3xl-extralight-uppercase;
  strong {
    @include text-3xl-semibold-uppercase;
  }

  @include breakpoints.md-desktop {
    @include text-mega-thin-uppercase;
    strong {
      @include text-mega-semibold-uppercase;
    }
  }
}

@mixin text-marquee-title {
  @include text-3xl-extralight;
  strong {
    @include text-3xl-semibold;
  }

  @include breakpoints.md-desktop {
    @include text-mega-thin;
    strong {
      @include text-mega-semibold;
    }
  }
}

@mixin text-big-title-uppercase {
  @include text-xxl-extralight-uppercase;
  strong {
    @include text-xxl-semibold-uppercase;
  }

  @include breakpoints.md-desktop {
    @include text-mega-thin-uppercase;
    strong {
      @include text-mega-semibold-uppercase;
    }
  }
}

@mixin text-big-title {
  @include text-xxl-extralight;
  strong {
    @include text-xxl-semibold;
  }

  @include breakpoints.md-desktop {
    @include text-mega-thin;
    strong {
      @include text-mega-semibold;
    }
  }
}

@mixin text-medium-title {
  @include text-xxl-extralight;
  strong {
    @include text-xxl-semibold;
  }

  @include breakpoints.md-desktop {
    @include text-3xl-extralight;
    strong {
      @include text-3xl-semibold;
    }
  }
}

@mixin text-small-title {
  @include text-xl-regular;
  strong {
    @include text-xl-light-uppercase;
  }

  @include breakpoints.md-desktop {
    @include text-3xl-regular;
    strong {
      @include text-3xl-extralight-uppercase;
    }
  }
}

@mixin text-mini-title {
  @include text-xl-light;
  strong {
    @include text-xl-semibold;
  }

  @include breakpoints.md-desktop {
    @include text-xxl-extralight;
    strong {
      @include text-xxl-semibold;
    }
  }
}

// Breakpoint-independent typography

@mixin text-mega-semibold-uppercase {
  font-size: 4.5rem;
  font-weight: $font-weight-semibold;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-mega-semibold {
  font-size: 4.5rem;
  // font-weight: $font-weight-semibold;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-mega-regular {
  font-size: 4.5rem;
  font-weight: $font-weight-regular;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-mega-thin-uppercase {
  font-size: 4.5rem;
  font-weight: $font-weight-thin;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-mega-thin {
  font-size: 4.5rem;
  font-weight: $font-weight-thin;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-3xl-extralight-uppercase {
  font-size: 3rem;
  font-weight: $font-weight-extralight;
  line-height: 97%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-3xl-extralight {
  font-size: 3rem;
  font-weight: $font-weight-extralight;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-3xl-semibold-uppercase {
  font-size: 3rem;
  font-weight: $font-weight-semibold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-3xl-semibold {
  font-size: 3rem;
  font-weight: $font-weight-semibold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-3xl-regular {
  font-size: 3rem;
  font-weight: $font-weight-regular;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xxl-extralight-uppercase {
  font-size: 2.5rem;
  font-weight: $font-weight-extralight;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xxl-regular {
  font-size: 2.5rem;
  font-weight: $font-weight-regular;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xxl-extralight {
  font-size: 2.5rem;
  font-weight: $font-weight-extralight;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xxl-semibold-uppercase {
  font-size: 2.5rem;
  font-weight: $font-weight-semibold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xxl-semibold {
  font-size: 2.5rem;
  font-weight: $font-weight-semibold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xl-semibold {
  font-size: 2rem;
  font-weight: $font-weight-semibold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xl-light-uppercase {
  font-size: 2rem;
  font-weight: $font-weight-light;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xl-light {
  font-size: 2rem;
  font-weight: $font-weight-light;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-xl-regular {
  font-size: 2rem;
  font-weight: $font-weight-regular;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-l-semibold {
  font-size: 1.25rem;
  font-weight: $font-weight-semibold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-l-regular {
  font-size: 1.25rem;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-m-bold {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-m-semibold {
  font-size: 1rem;
  font-weight: $font-weight-semibold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-m-regular {
  font-size: 1rem;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-s-bold-uppercase {
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-s-bold-uppercase-cta {
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  line-height: 100%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-s-regular-uppercase {
  font-size: 0.875rem;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-s-bold {
  font-size: 0.875rem;
  font-weight: $font-weight-bold;
  line-height: 100%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-s-regular {
  font-size: 0.875rem;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-xs-bold-uppercase {
  font-size: 0.75rem;
  font-weight: $font-weight-bold;
  line-height: 140%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-xs-regular-uppercase {
  font-size: 0.75rem;
  line-height: 140%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-xs-regular-uppercase-cta {
  font-size: 0.75rem;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
  text-transform: uppercase;
}

@mixin text-xs-bold-uppercase-cta {
  font-size: 0.75rem;
  font-weight: $font-weight-bold;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xs-semibold-uppercase-cta {
  font-size: 0.75rem;
  font-weight: $font-weight-semibold;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
  text-transform: uppercase;
}

@mixin text-xs-bold {
  font-size: 0.75rem;
  font-weight: $font-weight-bold;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-xs-regular {
  font-size: 0.75rem;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-xxs-bold-uppercase {
  font-size: 0.625rem;
  font-weight: $font-weight-bold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-xxs-bold {
  font-size: 0.625rem;
  font-weight: $font-weight-bold;
  line-height: 105%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-xxs-regular-uppercase {
  font-size: 0.625rem;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: 0.16em;
  }
  text-transform: uppercase;
}

@mixin text-xxs-semibold-uppercase-cta {
  font-size: 0.625rem;
  font-weight: $font-weight-semibold;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xxs-regular-uppercase-cta {
  font-size: 0.625rem;
  line-height: 120%;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
  text-transform: uppercase;
}

@mixin text-xxs-regular {
  font-size: 0.625rem;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-3xs-bold {
  font-size: 0.5rem;
  font-weight: $font-weight-bold;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.03em;
  }
}

@mixin text-3xs-regular {
  font-size: 0.5rem;
  overflow-wrap: break-word;
  @include breakpoints.ltr-only {
    letter-spacing: -0.02em;
  }
}

@mixin text-xs-highlighted {
  @include text-xs-bold;
  @include colors.kisses-gradient;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-underline {
  text-decoration: underline;
  text-underline-offset: 3.5px;
}

@mixin text-xxs-highlighted {
  @include text-xxs-bold-uppercase;
  @include colors.kisses-gradient;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-xl-highlighted {
  @include text-xl-regular;
  @include colors.kisses-gradient;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin text-s-highlighted {
  @include text-s-bold;
  @include colors.kisses-gradient;
  @include breakpoints.ltr-only {
    letter-spacing: 2.24px;
  }
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
