$white: rgba(255, 255, 255, 1);
$dark: rgb(37, 43, 46);

$light-pink: rgba(255, 245, 245, 1);
$pink: rgba(214, 56, 104, 1);
$very-light-gray: rgba(240, 240, 240, 1);
$very-light-gray-opacity: rgba(240, 240, 240, 0.8);
$light-gray: rgba(216, 216, 216, 1);
$light-gray-opacity: rgba(181, 181, 181, 0.8);
$gray: rgba(181, 181, 181, 1);
$dark-gray: rgba(37, 43, 46, 1);
$red-ko: rgba(204, 12, 12, 1);
$fucsia: rgba(207, 19, 186, 1);
$fucsia2: rgb(219, 6, 173);
$blue: rgb(0, 0, 255);
$white-with-opacity: rgba(255, 255, 255, 0.8);

$success: rgb(5, 129, 17);
$error: rgb(204, 12, 12);

@mixin color-gradient {
  background: linear-gradient(
    90deg,
    rgba(251, 195, 193, 1) 0%,
    rgba(248, 190, 242, 1) 60%,
    rgba(207, 215, 249, 1) 100%
  );
}

@mixin border-color-gradient {
  border-image-source: linear-gradient(
    90deg,
    rgba(251, 195, 193, 1) 0%,
    rgba(248, 190, 242, 1) 60%,
    rgba(207, 215, 249, 1) 100%
  );
}

@mixin border-color-gradient-w-radius {
  position: relative;
  border-radius: 24px;
  background: $white;

  &::before {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: 24px;
    @include color-gradient();
    z-index: -1;
  }
}

@mixin color-gradient-light {
  background: linear-gradient(
    90deg,
    rgba(255, 224, 223, 1) 0%,
    rgba(249, 218, 239, 1) 60%,
    rgba(236, 231, 249, 1) 100%
  );
}

@mixin color-gradient-light-glass {
  background: linear-gradient(
    90deg,
    rgba(255, 224, 223, 0.65) 0%,
    rgba(249, 218, 239, 0.65) 60%,
    rgba(236, 231, 249, 0.65) 100%
  );
  backdrop-filter: blur(10px);
}

@mixin color-gradient-light-opacity {
  background: linear-gradient(
    90deg,
    rgba(255, 224, 223, 0.8) 0%,
    rgba(249, 218, 239, 0.8) 60%,
    rgba(236, 231, 249, 0.8) 100%
  );
}

@mixin kisses-gradient {
  background: linear-gradient(90deg, rgba(219, 6, 173, 1) 0%, rgb(218, 12, 155) 29.69%, rgb(212, 65, 2) 100%);
}

@mixin white-gradient {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.2) 100%);
  backdrop-filter: blur(10px);
}

@mixin black-gradient {
  background: linear-gradient(45deg, rgba(37, 43, 46, 0.6) 0%, rgba(37, 43, 46, 0.2) 100%);
  backdrop-filter: blur(10px);
}

@mixin glass-gradient {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.65) 100%);
  backdrop-filter: blur(10px);
}

@mixin peach-gradient {
  background: linear-gradient(
    90.07deg,
    rgba(219, 144, 0, 0.4) 3.11%,
    rgba(219, 92, 0, 0.4) 15.54%,
    rgba(250, 5, 123, 0.4) 76.16%,
    rgba(250, 5, 225, 0.4) 91.34%
  );
}
