$screen-tablet-min: 640px;
$screen-sm-desktop-min: 900px;
$screen-md-desktop-min: 1280px;
$screen-lg-desktop-min: 1920px;

@mixin mobile-small-only {
  @media (max-width: ($screen-tablet-min - 1px)) {
    @content;
  }
}

@mixin mobile-only {
  @media (max-width: ($screen-sm-desktop-min - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-tablet-min) {
    @content;
  }
}

@mixin sm-desktop {
  @media (min-width: $screen-sm-desktop-min) {
    @content;
  }
}

@mixin between-sm-md {
  @media (min-width: $screen-sm-desktop-min) and (max-width: $screen-md-desktop-min) {
    @content;
  }
}

@mixin md-desktop {
  @media (min-width: $screen-md-desktop-min) {
    @content;
  }
}

@mixin lg-desktop {
  @media (min-width: $screen-lg-desktop-min) {
    @content;
  }
}

@mixin rtl-only {
  html[dir="rtl"] & {
    @content;
  }
}

@mixin ltr-only {
  html[dir="ltr"] & {
    @content;
  }
}
